import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import "@styles/landing.less";
import Header from "./Header";
import Footer from "./Footer";
import FooterContact from "./FooterContact";
import ContactSlider from "./ContactSlider";
import { useTranslation } from "react-i18next";
import { Col, Row, Layout, Image, Input, Form, Select, Button } from "antd";
import image from "@assets/images/contact-background.png";
import { sendEmail } from "@api";
import { openNotification } from '@common/helpers';
import { getSessionId } from "@api/MailContact";
import { useHistory } from "react-router-dom";

const { Option } = Select;

document.body.classList.add("full-scroll");

const layout = {
  wrapperCol: {
    span: 24,
  },
};

const Contact = () => {
  const user = useSelector((state) => state.user);
  const history = useHistory();
  const { t } = useTranslation();
  const messageType = [
    t("CONTACT_US_FORM.MESSAGE_TYPE1"),
    t("CONTACT_US_FORM.MESSAGE_TYPE2"),
    t("CONTACT_US_FORM.MESSAGE_TYPE3"),
    t("CONTACT_US_FORM.MESSAGE_TYPE4"),
  ];

  const [sessionId, setSessionId] = useState("");
  const [state, setstate] = useState({
    isPhoneValid: true,
    phoneNumber: "",
  });

  const onFinish = (formData) => {
    const body = {
      ...formData,
      type: 'CONTACT',
      clientId: process.env.REACT_APP_SSO_CLIENT_ID
    }
    sendEmail(body, sessionId).subscribe((res) => {
      if(res.messages.length > 0 && res.messages[0].code === "SUCCESS") {
        openNotification(
          'success',
          t("CONTACT_US_FORM.SEND_EMAIL_SUCCESS_MESSAGE")
        );
        setTimeout(() => {
          window.open(`/documents/Leaflet-Cloudsuite.pdf`, '_blank');
        }, 1500);
      } else {
        openNotification(
          'error',
          'The request has unsucceeded.'
        );
      }
    });
  };

  const onMessageCategoryChange = () => {
    // TODO
  };

  const validateInput = (e) => {
    const phone = e.target.value ? e.target.value : undefined;
    setstate({
      ...state,
      isPhoneValid: phone && !isNaN(phone),
      phoneNumber: phone ? phone : "",
    });
  };

  useEffect(() => {
    const subscription = getSessionId().subscribe((res) => {
      setSessionId(res.data);
    })
    return () => subscription.unsubscribe();
  }, []);

  return (
    <Layout className="layout">
      <div className="landing-layout">
        <Header user={user} />
        <ContactSlider menu={t("CONTACT_US_FORM.CONTACT")} title={t("CONTACT_US_FORM.CONTACT")} id="CONTACT_US" />
        <div className="contact-us-layout">
          <div className="contact-us-layout-content">
            <Row className="main-content" justify="space-around">
              <Col xs={24} lg={8} xl={8} className="contact-us-form-container">
                <div className="contact-us-form">
                  <h3>{t("CONTACT_US_FORM.CONTACT")}</h3>
                  <Form {...layout} name="nest-messages" onFinish={onFinish}>
                    <Form.Item
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: t("CONTACT_US_FORM.NAME_REQUIRE_MESSAGE"),
                        },
                      ]}
                    >
                      <Input placeholder={t("CONTACT_US_FORM.NAME_PLACEHOLDER")} />
                    </Form.Item>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          type: "email",
                          message: t("CONTACT_US_FORM.EMAIL_NOT_VALID_MESSAGE"),
                        },
                        {
                          required: true,
                          message: t("CONTACT_US_FORM.EMAIL_REQUIRE_MESSAGE"),
                        },
                      ]}
                    >
                      <Input placeholder={t("CONTACT_US_FORM.EMAIL_PLACEHOLDER")} />
                    </Form.Item>
                    <Form.Item
                      name="company"
                      rules={[
                        {
                          required: true,
                          message: t("CONTACT_US_FORM.COMPANY_REQUIRE_MESSAGE"),
                        },
                      ]}
                    >
                      <Input placeholder={t("CONTACT_US_FORM.COMPANY_PLACEHOLDER")} />
                    </Form.Item>
                    <Form.Item
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: t("CONTACT_US_FORM.PHONE_REQUIRE_MESSAGE"),
                        },
                      ]}
                      validateStatus={state.isPhoneValid ? undefined : "error"}
                      help={state.isPhoneValid ? undefined : t("CONTACT_US_FORM.PHONE_NOT_VALID_MESSAGE")}
                    >
                      <Input
                        placeholder={t("CONTACT_US_FORM.PHONE_PLACEHOLDER")}
                        onChange={validateInput}
                        value={state.phoneNumber}
                      />
                    </Form.Item>
                    <Form.Item
                      name="messageCategory"
                      rules={[
                        {
                          required: true,
                          message: t("CONTACT_US_FORM.CATEGORY_REQUIRE_MESSAGE"),
                        },
                      ]}
                    >
                      <Select
                        placeholder={t("CONTACT_US_FORM.CATEGORY_PLACEHOLDER")}
                        onChange={onMessageCategoryChange}
                        allowClear
                      >
                        {messageType.map((item, index) => {
                          return (
                            <Option value={item} key={index}>
                              {item}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="message"
                      rules={[
                        {
                          required: true,
                          message: t("CONTACT_US_FORM.MESAGE_AREA_REQUIRE_MESSAGE"),
                        },
                      ]}
                    >
                      <Input.TextArea placeholder={t("CONTACT_US_FORM.MESAGE_AREA_PLACEHOLDER")} rows={2} />
                    </Form.Item>
                    <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                      <Button htmlType="submit" className="form-button-submit">
                        {t("CONTACT_US_FORM.SUBMIT")}
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </Col>
              <Col xs={24} lg={15} xl={15} style={{ padding: "0" }}>
                <Image preview={false} src={image} />
              </Col>
            </Row>
          </div>
        </div>
        <Row>
          <FooterContact />
        </Row>
        <Footer />
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  user: state.common,
});

export default connect(mapStateToProps, null)(Contact);
