import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import "@styles/landing.less";
import Header from "./Header";
import Footer from "./Footer";
import FooterContact from "./FooterContact";
import ContactSlider from "./ContactSlider";
import { useTranslation } from "react-i18next";
import { Col, Row, Layout, Radio, Input, Form, Select, Button, Checkbox, Space } from "antd";
import { sendEmail } from "@api";
import { openNotification } from '@common/helpers';
import { getSessionId } from "@api/MailContact";

const { Option } = Select;

document.body.classList.add("full-scroll");

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 24,
  },
};

const numberEmployees = ["1-199", "200-999", "1,000-4,999", "5,000-14,999", "15,000-50,000", "More than 50,000"];
const countryList = [
  "COUNTRIES.ASIA_PACIFIC",
  "COUNTRIES.JAPAN",
  "COUNTRIES.US",
  "COUNTRIES.EMEA",
  "COUNTRIES.SEA",
  "COUNTRIES.OTHERS",
]
const cloudSpend = [
  "CLOUD_SPEND.UNDER_$50000",
  "CLOUD_SPEND.FROM_$50000_TO_$100000",
  "CLOUD_SPEND.FROM_$10000_TO_$250000",
  "CLOUD_SPEND.FROM_$250000_TO_$1000000",
  "CLOUD_SPEND.FROM_$1000000_OR_MORE",
  "CLOUD_SPEND.DONT_KNOW_DECLINE_TO_ANSWER",
];

const RequestDemo = () => {
  const user = useSelector((state) => state.user);
  const { t } = useTranslation();
  const [state, setstate] = useState({
    isPhoneValid: true,
    phoneNumber: "",
  });

  const [sessionId, setSessionId] = useState("");
  const onFinish = (formData) => {
    const body = {
      ...formData,
      type: 'DEMO',
      messageCategory: '',
      message: '',
      clientId: process.env.REACT_APP_SSO_CLIENT_ID
    }
    sendEmail(body, sessionId).subscribe((res) => {
      if (res.messages.length > 0 && res.messages[0].code === "SUCCESS") {
        openNotification(
          'success',
          t("CONTACT_US_FORM.SEND_EMAIL_SUCCESS_MESSAGE")
        );
      } else {
        openNotification(
          'error',
          'The request has unsucceeded.'
        );
      }
    });
  };

  const onMessageCategoryChange = () => {
    console.log("change == message category");
  };

  const validateInput = (e) => {
    const phone = e.target.value ? e.target.value : undefined;
    setstate({
      ...state,
      isPhoneValid: phone && !isNaN(phone),
      phoneNumber: phone ? phone : "",
    });
  };


  useEffect(() => {
    const subscription = getSessionId().subscribe((res) => {
      setSessionId(res.data);
    })
    return () => subscription.unsubscribe();
  }, []);

  return (
    <Layout className="layout">
      <div className="landing-layout">
        <Header user={user} />
        <ContactSlider menu={t("REQUEST_DEMO_CONTACT")} title={t("REQUEST_DEMO_TITLE")} id="REQUEST_DEMO" />
        <div className="request-demo-layout">
          <div className="request-demo-layout-content">
            <Form {...layout} name="nest-messages" onFinish={onFinish}>
              <Row className="main-content" justify="space-around">
                <Col xs={24} lg={8} className="request-demo-form-container">
                  <div className="request-demo-form">
                    <h2>{t("INFORMATION")}</h2>
                    <Form.Item
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: t("CONTACT_US_FORM.NAME_REQUIRE_MESSAGE"),
                        },
                      ]}
                    >
                      <Input placeholder={t("CONTACT_US_FORM.NAME_PLACEHOLDER")} />
                    </Form.Item>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          type: "email",
                          message: t("CONTACT_US_FORM.EMAIL_NOT_VALID_MESSAGE"),
                        },
                        {
                          required: true,
                          message: t("CONTACT_US_FORM.EMAIL_REQUIRE_MESSAGE"),
                        },
                      ]}
                    >
                      <Input placeholder={t("CONTACT_US_FORM.EMAIL_PLACEHOLDER")} />
                    </Form.Item>

                    <Form.Item
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: t("CONTACT_US_FORM.PHONE_REQUIRE_MESSAGE"),
                        },
                      ]}
                      validateStatus={state.isPhoneValid ? undefined : "error"}
                      help={state.isPhoneValid ? undefined : t("CONTACT_US_FORM.PHONE_NOT_VALID_MESSAGE")}
                    >
                      <Input
                        placeholder={t("CONTACT_US_FORM.PHONE_PLACEHOLDER")}
                        onChange={validateInput}
                        value={state.phoneNumber}
                      />
                    </Form.Item>
                    <Form.Item
                      name="company"
                      rules={[
                        {
                          required: true,
                          message: t("CONTACT_US_FORM.COMPANY_REQUIRE_MESSAGE"),
                        },
                      ]}
                    >
                      <Input placeholder={t("CONTACT_US_FORM.COMPANY_PLACEHOLDER")} />
                    </Form.Item>
                    <Form.Item
                      name="numberOfEmployees"
                      rules={[
                        {
                          required: true,
                          message: t("CONTACT_US_FORM.NUMBER_OF_EMPLOYEES_REQUIRE_MESSAGE"),
                        },
                      ]}
                    >
                      <Select
                        placeholder={t("CONTACT_US_FORM.NUMBER_OF_EMPLOYEES_PLACEHOLDER")}
                        onChange={onMessageCategoryChange}
                        allowClear
                      >
                        {numberEmployees.map((item, index) => {
                          return (
                            <Option value={t(item)} key={index}>
                              {item}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="country"
                      rules={[
                        {
                          required: true,
                          message: t("CONTACT_US_FORM.COUNTRY_REQUIRE_MESSAGE"),
                        },
                      ]}
                    >
                      <Select
                        placeholder={t("CONTACT_US_FORM.COUNTRY_PLACEHOLDER")}
                        onChange={onMessageCategoryChange}
                        allowClear
                      >
                        {countryList.map((item, index) => {
                          return (
                            <Option value={t(item)} key={index}>
                              {t(item)}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name="cloudSpend"
                      rules={[
                        {
                          required: true,
                          message: t("CONTACT_US_FORM.CLOUD_SPEND_PLACEHOLDER"),
                        },
                      ]}
                    >
                      <Select
                        placeholder={t("CONTACT_US_FORM.CLOUD_SPEND_PLACEHOLDER")}
                        onChange={onMessageCategoryChange}
                        allowClear
                      >
                        {cloudSpend.map((item, index) => {
                          return (
                            <Option value={t(item)} key={index}>
                              {t(item)}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
                <Col xs={24} lg={8} span={5} style={{ padding: "0" }}>
                  <div className="cloud-provider-container">
                    <h2>{t("CLOUD_PROVIDER.TITLE")}</h2>
                    <div className="cloud-provider-content">
                      <Form.Item
                        name="cloudProviders"
                        rules={[
                          {
                            required: true,
                            message: "Please select cloud provider!",
                          },
                        ]}
                      >
                        <Checkbox.Group>
                          <Row>
                            <Checkbox value={t("CLOUD_PROVIDER.AWS")}>{t("CLOUD_PROVIDER.AWS")}</Checkbox>
                          </Row>
                          <Row>
                            <Checkbox value={t("CLOUD_PROVIDER.AZURE")}>{t("CLOUD_PROVIDER.AZURE")}</Checkbox>
                          </Row>
                          <Row>
                            <Checkbox value={t("CLOUD_PROVIDER.GCP")}>{t("CLOUD_PROVIDER.GCP")}</Checkbox>
                          </Row>
                          <Row>
                            <Checkbox value={t("CLOUD_PROVIDER.ON_PREMISE")}>{t("CLOUD_PROVIDER.ON_PREMISE")}</Checkbox>
                          </Row>
                          <Row>
                            <Checkbox value={t("CLOUD_PROVIDER.OTHER_PUBLIC_CLOUD")}>{t("CLOUD_PROVIDER.OTHER_PUBLIC_CLOUD")}</Checkbox>
                          </Row>
                          <Row>
                            <Checkbox value={t("CLOUD_PROVIDER.NOT_USE")}>{t("CLOUD_PROVIDER.NOT_USE")}</Checkbox>
                          </Row>
                        </Checkbox.Group>
                      </Form.Item>
                    </div>
                  </div>
                </Col>
                <Col xs={24} lg={8} span={5} style={{ padding: "0" }}>
                  <div className="cloud-challenge-container">
                    <h2>{t("CLOUD_CHALLENGE.TITLE")}</h2>
                    <div className="cloud-challenge-content">
                      <Form.Item
                        name="cloudChallenges"
                        rules={[
                          {
                            required: true,
                            message: "Please select cloud challenges!",
                          },
                        ]}
                      >
                        <Radio.Group>
                          <Space direction="vertical">
                            <Radio value={t("CLOUD_CHALLENGE.SECURITY")}>{t("CLOUD_CHALLENGE.SECURITY")}</Radio>
                            <Radio value={t("CLOUD_CHALLENGE.MANAGE_CLOUD_SPEND")}>{t("CLOUD_CHALLENGE.MANAGE_CLOUD_SPEND")}</Radio>
                            <Radio value={t("CLOUD_CHALLENGE.GOVERNANCE")}>{t("CLOUD_CHALLENGE.GOVERNANCE")}</Radio>
                            <Radio value={t("CLOUD_CHALLENGE.COMPLIANCE")}>{t("CLOUD_CHALLENGE.COMPLIANCE")}</Radio>
                            <Radio value={t("CLOUD_CHALLENGE.CONTAINER_MANAGEMENT")}>{t("CLOUD_CHALLENGE.CONTAINER_MANAGEMENT")}</Radio>
                            <Radio value={t("CLOUD_CHALLENGE.LEVERAGE_OPERATION")}>{t("CLOUD_CHALLENGE.LEVERAGE_OPERATION")}</Radio>
                          </Space>
                        </Radio.Group>
                      </Form.Item>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Button htmlType="submit" className="request-demo-submit">
                  {t("CLOUD_CHALLENGE.REQUEST_DEMO")}
                </Button>
              </Row>
            </Form>
          </div>
        </div>

        <Row>
          <FooterContact />
        </Row>
        <Footer />
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  user: state.common,
});

export default connect(mapStateToProps, null)(RequestDemo);
