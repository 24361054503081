import { of } from "rxjs";
import { generateRandomString } from "../helpers";
import { keycloakLogIn, keycloakLogOut } from "./keycloak";
import { SSO_ACCESS_TOKEN } from "./constants";
import { isLoggedIn, getRoles } from './guards';
import { parseJwtToken, tokenIsExpired } from './token';

export function checkReloadRequire() {
  const storageKeys = Object.keys(localStorage);

  return storageKeys.some((k) => k.indexOf("kc-callback-") >= 0);
}

export function checkRetryLoginRequire() {
  const storageKeys = Object.keys(localStorage);

  return storageKeys.some((k) => k.indexOf("akc_retry_login") >= 0);
}

export function checkRetryLogout() {
  const storageKeys = Object.keys(localStorage);

  return storageKeys.some((k) => k.indexOf("akc_logout") >= 0);
}

export function retryLogin() {
  localStorage.setItem("akc_retry_login", generateRandomString(16));
  return logOut();
}

export function authenticationInit() {
  const token = localStorage.getItem(SSO_ACCESS_TOKEN);
  if (token) {
    const tokenObj = parseJwtToken(token);
  
    if (isLoggedIn() && getRoles() != null && !tokenIsExpired(tokenObj)) {
      return of(null);
    }
  }
  if (checkRetryLogout()) {
    localStorage.removeItem("akc_logout");
    // need init login with retry login behavior after logout
    if (checkRetryLoginRequire()) {
      localStorage.removeItem("akc_retry_login");

      return login();
    }

    return of(null);
  }

  // need init login with keycloak callback set
  if (checkReloadRequire()) {
    return login();
  }

  return of(null);
}

export function login() {
  return keycloakLogIn();
}

export function logOut() {
  localStorage.setItem("akc_logout", generateRandomString(16));
  return keycloakLogOut();
}

export * from "./constants";
export * from "./guards";